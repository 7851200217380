import React, { useState, useEffect } from "react";
import ApiService from "../ApiService";
import "./ConfigurationManagement.css";
import "./Invoice.css";

const Invoices = () => {
    const [invoice, setInvoice] = useState(null);
    const [invoices, setInvoices] = useState([]);
    const [page, setPage] = useState(0);


    useEffect(() => {
        fetchInvoices(page);
    }, [page]);

    const fetchInvoices = async (page) => {
        try {
            const response = await ApiService.getInvoices(page);
            setInvoices(response.data); // Assuming response structure similar to configurations
        } catch (error) {
            console.error("Error fetching servers:", error);
        }
    };

    const formatDate = (dateInMillis) => {
        const date = new Date(dateInMillis);
        return new Intl.DateTimeFormat("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    };


    return (
        <div className="config-management-container">
            <div className="server-list">
                <h3>Счета</h3>
                {invoices.map((invoice) => (
                    <div key={invoice.id} className="invoice-item">
                        <p className="invoice-date"> {formatDate(invoice.date)}</p>
                        <p>{invoice.amount} P</p>
                        <p>{invoice.paid ? "Оплачен" : "Не хватило средств"}</p>
                        <button onClick={() => setInvoice(invoice)}>Открыть</button>
                    </div>
                ))}

            </div>

            {invoice && <div className="config-form-container">
                <h3>Информация о выставленном счёте</h3>
                <p>{invoice.text}</p>
                <p>Cумма к списанию: {invoice.amount} P</p>
                <p>Статус счёта: {invoice.paid ? "Оплачен" : "Не хватило средств"}</p>
                <p className="invoice-date">{formatDate(invoice.date)}</p>
            </div>}

        </div>
    );
};

export default Invoices;
