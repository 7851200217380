import React, { useState, useEffect } from "react";
import ApiService from "../ApiService";
import "./ConfigurationManagement.css";

const TicketManagement = () => {
    const [ticket, setTicket] = useState({
        ticketId: null,
        ticketClientId: 0,
        answer: "",
        ticket: "",
        answerDate: null,
        ticketCreatedDate: Date.now(),
        updatedAt: Date.now(),
        ticketTitle: "",
        ticketStatus: "OPEN",
    });
    const [tickets, setTickets] = useState([]);
    const [page, setPage] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedTicketId, setSelectedTicketId] = useState(null);

    useEffect(() => {
        fetchTickets(page);
    }, [page]);

    const fetchTickets = async (page) => {
        try {
            const response = await ApiService.getAdminAllTickets(page);
            setTickets(response.data); // Assuming the response has a `data` field
        } catch (error) {
            console.error("Error fetching tickets:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTicket({
            ...ticket,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await ApiService.updateTicket(ticket);
            console.log("Ticket updated successfully");
            fetchTickets(page); // Refresh the list after adding/editing
            resetForm();
        } catch (error) {
            console.error("Error submitting ticket:", error);
        }
    };

    const handleEdit = (ticket) => {
        setTicket(ticket);
        setIsEditMode(true);
        setSelectedTicketId(ticket.ticketId);
    };

    const resetForm = () => {
        setTicket({
            ticketId: null,
            ticketClientId: 0,
            answer: "",
            ticket: "",
            answerDate: null,
            ticketCreatedDate: Date.now(),
            updatedAt: Date.now(),
            ticketTitle: "",
            ticketStatus: "OPEN",
        });
        setIsEditMode(false);
        setSelectedTicketId(null);
    };

    const handlePageChange = (direction) => {
        if (direction === "next") {
            setPage((prev) => prev + 1);
        } else if (direction === "prev" && page > 0) {
            setPage((prev) => prev - 1);
        }
    };

    const handleCreateNewTicket = () => {
        resetForm(); // Reset the form fields for new ticket
    };

    return (
        <div className="config-management-container">
            <div className="server-list">
                <h3>Ticket List</h3>
                {tickets.map((ticket) => (
                    <div key={ticket.ticketId} className="config-item">
                        <p>{ticket.ticketTitle} - Status: {ticket.ticketStatus}</p>
                        <p>ID: {ticket.ticketId}</p>
                        <button onClick={() => handleEdit(ticket)}>Edit</button>
                    </div>
                ))}
                <div className="pagination">
                    <button onClick={() => handlePageChange("prev")} disabled={page === 0}>Previous</button>
                    <button onClick={() => handlePageChange("next")}>Next</button>
                </div>
            </div>

            <div className="config-form-container">
                <button onClick={handleCreateNewTicket} className="create-config-btn">
                    Create New Ticket
                </button>

                <form onSubmit={handleSubmit} className="config-form">
                    <label>Ticket ID: {ticket.ticketId || "New"}</label>
                    <label>
                        Title:
                        <input type="text" name="ticketTitle" value={ticket.ticketTitle} onChange={handleChange} />
                    </label>
                    <label>
                        Client ID:
                        <input type="number" name="ticketClientId" value={ticket.ticketClientId} onChange={handleChange} />
                    </label>
                    <label>
                        Ticket Content:
                        <textarea name="ticket" value={ticket.ticket} onChange={handleChange}></textarea>
                    </label>
                    <label>
                        Answer:
                        <textarea name="answer" value={ticket.answer} onChange={handleChange}></textarea>
                    </label>
                    <label>
                        Status:
                        <select name="ticketStatus" value={ticket.ticketStatus} onChange={handleChange}>
                            <option value="OPEN">OPEN</option>
                        
                            <option value="ANSWERED">ANSWERED</option>
                        </select>
                    </label>
                    <button type="submit">{isEditMode ? "Update Ticket" : "Add Ticket"}</button>
                </form>
            </div>
        </div>
    );
};

export default TicketManagement;
