import React, { useState, useEffect } from "react";
import { ReactComponent as EtyCloudLogo } from '../etyCloudBlack.svg';
import serversIcon from '../icons/servers.svg';
import ticketsIcon from '../icons/tickets.svg';
import notificationsIcon from '../icons/notifications.svg';
import billsIcon from '../icons/bills.svg';
import ApiService from "../ApiService";

const Sidebar = ({ activeTab, setActiveTab, username, role, initialDepositAmount, client }) => {

  const [isPopupOpen, setIsPopupOpen] = useState(Boolean(initialDepositAmount));
  const [depositAmount, setDepositAmount] = useState(Number(initialDepositAmount) || 5000); // Default or query param amount



  const handleDepositSubmit = async () => {
    try {
      const response = await ApiService.createDeposit(depositAmount);

      window.open(response.data, '_blank'); // Opens payment link in new tab
      setIsPopupOpen(false); // Close the popup after payment link is opened
    } catch (error) {
      console.error("Error creating deposit:", error);
    }
  };



  return (
    <div className="sidebar">
      <EtyCloudLogo style={{ marginBottom: "20px", width: "150px", height: "55px" }} />
      <div
        className={`sidebar-item ${activeTab === "servers" ? "active" : ""}`}
        onClick={() => setActiveTab("servers")}
      >
        <img src={serversIcon} alt="Servers Icon" className="sidebar-icon" />
        Серверы
      </div>
      <div
        className={`sidebar-item ${activeTab === "tickets" ? "active" : ""}`}
        onClick={() => setActiveTab("tickets")}
      >
        <img src={ticketsIcon} alt="Tickets Icon" className="sidebar-icon" />
        Тикеты
      </div>
      {/* <div
        className={`sidebar-item ${activeTab === "notifications" ? "active" : ""}`}
        onClick={() => setActiveTab("notifications")}
      >
        <img src={notificationsIcon} alt="Notifications Icon" className="sidebar-icon" />
        Уведомления
      </div> */}
      <div
        className={`sidebar-item ${activeTab === "bills" ? "active" : ""}`}
        onClick={() => setActiveTab("bills")}
      >
        <img src={billsIcon} alt="Bills Icon" className="sidebar-icon" />
        Счета
      </div>
      {role === "ADMIN" && (
        <div
          className={`sidebar-item ${activeTab === "configurations" ? "active" : ""}`}
          onClick={() => setActiveTab("configurations")}
        >
          <img src={billsIcon} alt="Config Icon" className="sidebar-icon" />
          Управление конфигурациями
        </div>
      )}

      {role === "ADMIN" && (
        <div
          className={`sidebar-item ${activeTab === "admservers" ? "active" : ""}`}
          onClick={() => setActiveTab("admservers")}
        >
          <img src={billsIcon} alt="Config Icon" className="sidebar-icon" />
          Управление серверами
        </div>
      )}

      {role === "ADMIN" && (
        <div
          className={`sidebar-item ${activeTab === "admtickets" ? "active" : ""}`}
          onClick={() => setActiveTab("admtickets")}
        >
          <img src={billsIcon} alt="Config Icon" className="sidebar-icon" />
          Управление тикетами
        </div>
      )}
      <div className="sidebar-footer">
        {client != null && (
          <div>
        
            <p className="balance">{client.email}</p>
            <span className="username">{username}</span> <span className="balance">{client.balance} Р</span>
            <button onClick={() => setIsPopupOpen(true)} className="deposit-button">Пополнить баланс</button>
          </div>
        )}
      </div>

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h3>Выберите сумму пополнения</h3>
            <input
              type="number"
              value={depositAmount}
              onChange={(e) => setDepositAmount(Number(e.target.value))}
            />
            <button onClick={handleDepositSubmit} className="deposit-button">Пополнить</button>
            <button onClick={() => setIsPopupOpen(false)} className="cancel-button">Отмена</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
