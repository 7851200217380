import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const AUTH_API_URL = 'https://auth.ety.one/auth';
const CLOUD_API_URL = 'https://cloud.ety.one/api';
const PAYMENTS_API_BASE_URL = 'https://checkout.ety.one';

const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000 < Date.now(); // exp is in seconds, Date.now() returns ms
};

const checkTokenAndRefresh = async () => {
    let token = localStorage.getItem("token");
    if (token && isTokenExpired(token)) {
        const refreshToken = localStorage.getItem("refreshToken");
        try {
            const data = await ApiService.refresh(refreshToken);
            localStorage.setItem("token", data.data.accessToken); // Store new token
            localStorage.setItem("refreshToken", data.data.refreshToken); // Store new refresh token
            token = data.data.accessToken; // Update token
        } catch (error) {
            console.error("Failed to refresh token:", error);
            throw new Error("Token refresh failed");
        }
    }
    return token;
};

const ApiService = {
    login: async (username, password) => {
        try {
            const response = await axios.post(`${AUTH_API_URL}/login`, {
                username,
                password,
            });
            return response.data; // Access and refresh tokens
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    refresh: async (refreshToken) => {
        try {
            const response = await axios.post(`${AUTH_API_URL}/refresh?refreshToken=` + refreshToken, {
                refreshToken,
            });
            return response; // New access token
        } catch (error) {
            throw error.response.data; // Handle error responses
        }
    },


    createTicket: async (ticket) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.post(
                `${CLOUD_API_URL}/tickets/createTicket`,
                ticket,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    updateConfig: async (configuration) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.post(
                `${CLOUD_API_URL}/configs/updateConfiguration`,
                configuration,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    updateServer: async (server) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.post(
                `${CLOUD_API_URL}/servers/updateServer`,
                server,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    
    updateTicket: async (ticket) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.post(
                `${CLOUD_API_URL}/tickets/updateTicket`,
                ticket,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    getFilteredConfigs: async (page, hdd, ssd, storage, memory) => {
        try {

            const response = await axios.get(
                `${CLOUD_API_URL}/configs/get`,
                {
                    params: {
                        page: page,
                        hdd: hdd,
                        ssd: ssd,
                        storage: storage,
                        memory: memory,
                    }
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },


    getMyServers: async (page) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/servers/getMyServers?page=` + page,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            console.error(error)
        }
    },

    getMyTickets: async (page) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/tickets/getMine?page=` + page,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            console.error(error)
        }
    },


    order: async (id) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/servers/order?id=` + id,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            console.error(error)
        }
    },

    createDeposit: async (amount) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `https://checkout.ety.one/payment/generatePaymentLink?service=ETYCLOUD&amount=` + amount,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    getClient: async () => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/client/get`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    getInvoices: async () => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/client/getInvoices`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    getSpecificConfig: async (id) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/configs/getSpecific?id=` + id,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    
    getAdminAllTickets: async (page) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/tickets/getAllAdmin?page=` + page,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    getAdminAllConfigs: async (page) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/configs/getAdmin?page=` + page,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },

    getAdminAllServers: async (page) => {
        try {
            const accessToken = await checkTokenAndRefresh(); // Ensure valid token
            const response = await axios.get(
                `${CLOUD_API_URL}/servers/getServers?page=` + page,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data; // Handle success response
        } catch (error) {
            throw error.response.data; // Handle error response
        }
    },
};

export default ApiService;
