import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import { ReactComponent as EtyLogo } from './etyLogo.svg';
import { ReactComponent as SignIn } from './signIn.svg';
import { ReactComponent as EtyCloudLogo } from '../etyCloudLogo.svg';
import ApiService from '../ApiService';


const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [language, setLanguage] = useState('en'); // Language state
    const navigate = useNavigate(); // Initialize useNavigate
    const [error, setError] = useState('');


    const handleSignIn = async () => {
        try {
            setError('')
     
            const answer = await ApiService.login(username, password);
            localStorage.setItem("token", answer.accessToken);
            localStorage.setItem("refreshToken", answer.refreshToken);
            await ApiService.getClient();
             navigate('/dashboard');
        } catch (errorResponse) {
            setError(handleErrorResponse(errorResponse));
        }
    };

    const handleErrorResponse = (errorResponse) => {
        const errors = {
            en: {
                USERNAME_FORMAT_ERROR: 'Invalid username format.',
                PASSWORD_UNSECURE_ERROR: 'Password is not secure enough.',
                ACCOUNT_EXISTS: 'An account with this email already exists.',
                REGION_DISALLOW_ERROR: 'Sign up is not allowed in your region.',
                EMAIL_FORMAT_ERROR: 'Invalid email format.',
                UNKNOWN_ERROR: 'Check your password',
            },
            ru: {
                USERNAME_FORMAT_ERROR: 'Неверный формат имени пользователя.',
                PASSWORD_UNSECURE_ERROR: 'Пароль недостаточно безопасен.',
                ACCOUNT_EXISTS: 'Аккаунт с этим email уже существует.',
                REGION_DISALLOW_ERROR: 'Регистрация запрещена в вашем регионе.',
                EMAIL_FORMAT_ERROR: 'Неверный формат электронной почты.',
                UNKNOWN_ERROR: 'При авторизации произошла ошибка. Проверьте введёные данные.',
            },
        };

        return errors[language][errorResponse] || errors[language].UNKNOWN_ERROR;
    };


    const handleSignUp = () => {
        window.open('https://ety.one/register?referer=etycloud', '_blank');
    };

    const toggleLanguage = () => {
        setLanguage(language === 'en' ? 'ru' : 'en');
    };

    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;

      
        if (browserLanguage.toLowerCase().includes('ru')) {
            setLanguage('ru');
        } else {
            setLanguage('en');
        }
    }, []);

    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-header">
                    <div className="logoCon2">
                        <EtyCloudLogo className="logo2" />
                    </div>
                    <h2>{language === 'en' ? 'Sign in etyCloud' : 'Вход в etyCloud'}</h2>
                    <p>{language === 'en' ? 'Sign in to your etyId account' : 'Войдите в свой аккаунт etyID'}</p>
                </div>

                <div className="login-form">
                    {error && <p className="error-message">{error}</p>}
                    <input
                        type="text"
                        placeholder={language === 'en' ? 'Username' : 'Имя пользователя'}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className="password-field">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            placeholder={language === 'en' ? 'Password' : 'Пароль'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />


                    </div>

                    <button className="sign-in-btn" onClick={handleSignIn}>
                        <SignIn /> {language === 'en' ? 'Sign in with etyID' : 'Войти с etyID'}
                    </button>
                    <button className="sign-up-btn" onClick={handleSignUp}>
                        <EtyLogo className='ety-img' /> {language === 'en' ? 'Create etyID Account' : 'Создать аккаунт etyID'}
                    </button>
                </div>
                <button className="language-toggle" onClick={toggleLanguage}>
                    {language === 'en' ? 'Switch to Russian' : 'Переключиться на английский'}
                </button>
            </div>
        </div>
    );
};

export default LoginPage;
