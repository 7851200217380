import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import './ServerSelector.css';
import ApiService from '../ApiService';

const ServerSelector = () => {
  const [configs, setServers] = useState([]);
  const [page, setPage] = useState(0);
  const [hdd, setHdd] = useState(false);
  const [ssd, setSsd] = useState(false);
  const [storage, setStorage] = useState(0);
  const [memory, setMemory] = useState(0);
  const [loading, setLoading] = useState(false);

  // State for managing order confirmation
  const [confirmOrder, setConfirmOrder] = useState(null);

  // Create a reference for the element to scroll to
  const topRef = useRef(null);

  const navigate = useNavigate(); // Set up navigation

  const fetchServers = async () => {
    setLoading(true);
    try {
      const data = await ApiService.getFilteredConfigs(page, hdd, ssd, storage, memory);
      setServers(data.data);
    } catch (error) {
      console.error('Failed to fetch configs', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServers();
    if (topRef.current) {
      topRef.current.scrollIntoView();

      // Then apply the 10vh offset
      const offset = window.innerHeight * 0.08;
      window.scrollBy({
        top: -offset,
      });
    }
  }, [page]);

  const handleFilterChange = () => {
    setPage(0); // Reset page when filters are applied
    fetchServers();
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  // Function to handle server order confirmation
  const handleOrderClick = (serverId, price) => {
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {
      navigate('/login');
    } else {
      setConfirmOrder({ serverId, price });
    }
  };

  // Confirm and execute order
  const confirmOrderHandler = async () => {

    if (confirmOrder) {
      console.log("confirmed")
      try {
        const response = await ApiService.order(confirmOrder.serverId);
        if (response.type === "success") {
        
          navigate('/dashboard');
        } else {
       
          navigate(`/dashboard?deposit=${confirmOrder.price}`);
        }
     
      } catch (error) {
        console.error('Order failed', error);
      } finally {
        setConfirmOrder(null); // Reset confirmation state
      }
    }
  };

  return (
    <div>
      <div ref={topRef} /> {/* Element to scroll to */}
      <div className="config-selector">
        {/* Filter Section */}
        <div className="filter-section">
          <div className="filter-container">
            <h3>Фильтры</h3>
            <label>Объём диска (от, ГБ)</label>
            <input
              type="number"
              value={storage}
              onChange={(e) => setStorage(e.target.value)}
              placeholder="от, ГБ"
            />
            <div className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  checked={ssd}
                  onChange={() => setSsd(!ssd)}
                />
                SSD
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={hdd}
                  onChange={() => setHdd(!hdd)}
                />
                HDD
              </label>
            </div>
            <label>Объём RAM (от, ГБ)</label>
            <input
              type="number"
              value={memory}
              onChange={(e) => setMemory(e.target.value)}
              placeholder="от, ГБ"
            />
            <button onClick={handleFilterChange}>Применить</button>
          </div>
        </div>

        {/* Server List Section */}
        <div className="config-list">
          {loading ? (
            <p>Загрузка серверов...</p>
          ) : configs.length > 0 ? (
            configs.map((config, index) => (
              <div className="config-item-m" key={index}>
                <div className="config-info">
                  <div className='hor-pops'>
                    <div className="config-location">{config.location}</div>
                    <div className="config-channel">{config.channel}</div>
                  </div>
                  <h3>{config.processor}</h3>
                  <p>{config.memory} {config.memoryType}, {config.storageType}</p>
                </div>
                <div className="config-price">
                  <p>{config.price} ₽</p>
                  <button onClick={() => handleOrderClick(config.id, config.price)}>Заказать</button>
                </div>
              </div>
            ))
          ) : (
            <p>Нет доступных серверов</p>
          )}
          <p>Цены указаны за месяц аренды</p>
        </div>
      </div>

      {/* Pagination Section */}
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={page === 0}>
          Назад
        </button>
        <span>Страница {page + 1}</span>
        <button onClick={handleNextPage} disabled={configs.length < 6}>
          Вперёд
        </button>
      </div>

      {/* Order Confirmation Popup */}
      {/* {confirmOrder && (
        <div className="popup">
          <p>Подтвердите заказ на сервер за {confirmOrder.price} ₽</p>
          <button onClick={confirmOrderHandler}>Подтвердить</button>
          <button onClick={() => setConfirmOrder(null)}>Отмена</button>
        </div>
      )} */}

      {confirmOrder && (
        <div className="popup">
          <div className="popup-content">
            <h3>Подтвердите заказ на сервер за {confirmOrder.price} ₽</h3>
          
            <button  onClick={confirmOrderHandler} className="deposit-button">Заказать</button>
            <button onClick={() => setConfirmOrder(null)}className="cancel-button">Отмена</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServerSelector;
