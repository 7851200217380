
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import ApiService from "../ApiService";

const TicketList = ({ selectedTicket, setSelectedTicket, client }) => {
  const navigate = useNavigate();

  const handleOrderServerClick = () => {
    navigate("/");
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");


  const handleTicketPopup = async () => {
    // try {
    //   const response = await ApiService.createDeposit(depositAmount);
    //   alert(response.data);
    //   window.open(response.data, '_blank'); // Opens payment link in new tab
    //   setIsPopupOpen(false); // Close the popup after payment link is opened
    // } catch (error) {
    //   console.error("Error creating deposit:", error);
    // }
  };


  const fetchServers = async () => {
    try {
        const response = await ApiService.getMyTickets(0);
        setTickets(response.data);
    } catch (error) {
        console.error("Failed to fetch servers:", error);
    }
};

useEffect(() => {
    
    fetchServers(); // Fetch servers when component mounts or page changes
}, []);

  const handleCreateNewTicket = async () => {
    try {
      const response = await ApiService.createTicket({
        ticket: text,
        ticketTitle: title
      });
      if(response.type === "success")
      {
        setIsPopupOpen(false); // Close the popup after payment link is opened
      }
   
    } catch (error) {
      alert("Ошибка отправки тикета. Попробуйте позже")
      console.error("Error creating deposit:", error);
    }
  };



  return (
    <div className="server-list">
      <h3>Ваши тикеты</h3>




      {/* Show a message if the client cannot order a new server */}
      <button
        className="order-server-btn"
        onClick={() => { setIsPopupOpen(true) }}

      >
        Открыть тикет
      </button>
      {/* Show a message if client data is missing */}
      {!client && (
        <p className="limitation-message">
          Не удалось загрузить данные клиента. Пожалуйста, попробуйте еще раз.
        </p>
      )}

      {tickets.map((ticket) => (
        <div
          key={ticket.ticketTitle}
          className={`server-item ${selectedTicket && selectedTicket.ticketId === ticket.ticketId ? "selected" : ""} ${ticket.ticketStatus === "ANSWERED" ? "answered" : ""}`}
          onClick={() => setSelectedTicket(ticket)}
        >
          <div className="server-name">{ticket.ticketTitle}</div>
          <div className="server-ip">{ticket.ticketStatus === "OPEN" ? "Открыт" : "Отвечен"}{", #" + ticket.ticketId}</div>
        </div>
      ))}

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h3>Новый тикет</h3>
            <input
              type="text"
              value={title}
              placeholder="Тема"
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="text"
              value={text}
              placeholder="Текст"
              onChange={(e) => setText(e.target.value)}
            />
            <button onClick={handleCreateNewTicket} className="deposit-button">Создать</button>
            <button onClick={() => setIsPopupOpen(false)} className="cancel-button">Отмена</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketList;
