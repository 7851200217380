import React from "react";
import { useNavigate } from "react-router-dom";

const ServerList = ({ servers, selectedServer, setSelectedServer, client }) => {
  const navigate = useNavigate();

  const handleOrderServerClick = () => {
    navigate("/");
  };

  // Count servers by status
  const availableServers = servers.filter(server => server.status === "AVAILABLE").length;
  const waitingServers = servers.filter(server => server.status === "WAITING_FOR_ACTIVATION").length;

  // Determine if ordering a new server should be allowed, with a null-check for client
  const canOrderServer =
    (client?.isVerified || availableServers <= 5) &&
    waitingServers <= 5;

  return (
    <div className="server-list">
      <h3>Ваши серверы</h3>




      {/* Show a message if the client cannot order a new server */}
      {!canOrderServer ?
        <p className="limitation-message">
          {availableServers > 5 && !(client?.isVerified)
            ? "Для новых заказов требуется верификация аккаунта. Создайте тикет в поддержке"
            : "Невозможно заказать новый сервер: у вас более 3 серверов, ожидающих активации."}
        </p>
        :
        <button
          className="order-server-btn"
          onClick={handleOrderServerClick}
          disabled={!canOrderServer} // Disable button if ordering is not allowed
        >
          Заказать сервер
        </button>}

      {/* Show a message if client data is missing */}
      {!client && (
        <p className="limitation-message">
          Не удалось загрузить данные клиента. Пожалуйста, попробуйте еще раз.
        </p>
      )}

      {servers.map((server) => (
        <div
          key={server.name}
          className={`server-item ${selectedServer && selectedServer.id === server.id ? "selected" : ""}`}
          onClick={() => setSelectedServer(server)}
        >
          <div className="server-name">{server.name}</div>
          <div className="server-ip">{server.ip}</div>
        </div>
      ))}
    </div>
  );
};

export default ServerList;
