import React, { useState, useEffect } from "react";
import ApiService from "../ApiService";
import "./ConfigurationManagement.css";

const ServerManagement = () => {
    const [server, setServer] = useState({
        name: "",
        clientId: null,
        configurationId: null,
        lastPaymentDate: Date.now(),
        createDate: Date.now(),
        updatedAt: Date.now(),
        ip: "",
        status: "WAITING_FOR_ACTIVATION",
    });
    const [servers, setServers] = useState([]);
    const [page, setPage] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedServerId, setSelectedServerId] = useState(null);

    useEffect(() => {
        fetchServers(page);
    }, [page]);

    const fetchServers = async (page) => {
        try {
            const response = await ApiService.getAdminAllServers(page);
            setServers(response.data); // Assuming response structure similar to configurations
        } catch (error) {
            console.error("Error fetching servers:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        // Convert date strings to milliseconds for date inputs
        let newValue = value;
        if (name === "lastPaymentDate" || name === "createDate") {
            newValue = new Date(value).getTime(); // Convert to milliseconds
        }
    
        setServer({
            ...server,
            [name]: newValue,
        });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await ApiService.updateServer(server);
            fetchServers(page); // Refresh the list after adding/editing
            resetForm();
        } catch (error) {
            console.error("Error submitting server:", error);
        }
    };

    const handleEdit = (server) => {
        setServer(server);
        setIsEditMode(true);
        setSelectedServerId(server.id);
    };

    const resetForm = () => {
        setServer({
            name: "",
            clientId: null,
            configurationId: null,
            lastPaymentDate: Date.now(),
            createDate: Date.now(),
            updatedAt: Date.now(),
            ip: "",
            status: "WAITING_FOR_ACTIVATION",
        });
        setIsEditMode(false);
        setSelectedServerId(null);
    };

    const handlePageChange = (direction) => {
        if (direction === "next") {
            setPage((prev) => prev + 1);
        } else if (direction === "prev" && page > 0) {
            setPage((prev) => prev - 1);
        }
    };

    const handleCreateNewServer = () => {
        resetForm();
    };

    return (
        <div className="config-management-container">
            <div className="server-list">
                <h3>Server List</h3>
                {servers.map((server) => (
                    <div key={server.id} className="config-item">
                        <p>{server.name} - Status: {server.status}</p>
                        <p>ID: {server.id} - Client ID: {server.clientId}</p>
                        <button onClick={() => handleEdit(server)}>Edit</button>
                    </div>
                ))}
                <div className="pagination">
                    <button onClick={() => handlePageChange("prev")} disabled={page === 0}>Previous</button>
                    <button onClick={() => handlePageChange("next")}>Next</button>
                </div>
            </div>
            
            <div className="config-form-container">
                <button onClick={handleCreateNewServer} className="create-config-btn">
                    Create New Server
                </button>

                <form onSubmit={handleSubmit} className="config-form">
                    <label>
                        Server Name:
                        <input type="text" name="name" value={server.name} onChange={handleChange} />
                    </label>
                    <label>
                        Client ID:
                        <input type="number" name="clientId" value={server.clientId || ""} onChange={handleChange} />
                    </label>
                    <label>
                        Configuration ID:
                        <input type="number" name="configurationId" value={server.configurationId || ""} onChange={handleChange} />
                    </label>
                    <label>
                        Last Payment Date:
                        <input type="date" name="lastPaymentDate" value={new Date(server.lastPaymentDate).toISOString().split("T")[0]} onChange={handleChange} />
                    </label>
                    <label>
                        Create Date:
                        <input type="date" name="createDate" value={new Date(server.createDate).toISOString().split("T")[0]} onChange={handleChange} />
                    </label>
                    <label>
                        IP:
                        <input type="text" name="ip" value={server.ip} onChange={handleChange} />
                    </label>
                    <label>
                        Status:
                        <select name="status" value={server.status} onChange={handleChange}>
                            <option value="AVAILABLE">AVAILABLE</option>
                            <option value="DELETED">DELETED</option>
                            <option value="WAITING_FOR_ACTIVATION">WAITING_FOR_ACTIVATION</option>
                            <option value="SERVICE">SERVICE</option>
                        </select>
                    </label>
                    <button type="submit">{isEditMode ? "Update Server" : "Add Server"}</button>
                </form>
            </div>
        </div>
    );
};

export default ServerManagement;
