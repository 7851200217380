import React, { useRef } from 'react';
import './HomePage.css';
import { ReactComponent as EtyCloudLogo } from '../etyCloudLogo.svg';
import { ReactComponent as ServerImage } from '../server.svg';
import supportImage from '../support.svg'; // Assuming you have a PNG image
import protectionImage from '../protection.svg'; // Assuming you have a PNG image
import lowPricesImage from '../low-prices.svg'; // Assuming you have a PNG image
import believeImage from '../believe.svg'; // Assuming you have a PNG image
import { Link } from 'react-router-dom';
import ServerSelector from '../components/ServerSelector';

const HomePage = () => {
  const serverSelectorRef = useRef(null); // Create a reference for ServerSelector

  const handleScrollToServers = () => {
   // serverSelectorRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="homepage">
      {/* Header */}
      <header className="header">
        <div className="logo">
          <EtyCloudLogo className="etyLogo" />
        </div>
        <Link to="/login" className="login-btn">Войти в ЛК</Link> {/* Changed to Link */}
      </header>

      {/* Main Content */}
      <section className="main-section">
        <div className="main-content">
          <h1>Подберём сервер под Вас</h1>
          <p className="region">В Европейском регионе</p>
          <button className="cta-btn" onClick={() => {handleScrollToServers();}}>
            Смотреть серверы
          </button>
        </div>
        <div className="main-image">
          <ServerImage alt="Server" />
        </div>
      </section>

      {/* Bonus Section */}
      <section className="bonus-section">
        <h2>Бесплатно бонусом</h2>
        <div className="bonus-items">
          <div className="bonus-item">
            <div className="bonus-image">
              <img src={supportImage} alt="Поддержка" />
            </div>
            <div className="bonus-text">
              <h3>Поддержка</h3>
              <p>Поможем, если что-то пойдёт не так</p>
            </div>
          </div>

          <div className="bonus-item">
            <div className="bonus-image">
              <img src={protectionImage} alt="Защита" />
            </div>
            <div className="bonus-text">
              <h3>Защита</h3>
              <p>Настроим за Вас проксирование через Cloudflare по запросу</p>
            </div>
          </div>

          <div className="bonus-item">
            <div className="bonus-image">
              <img src={lowPricesImage} alt="Низкие цены" />
            </div>
            <div className="bonus-text">
              <h3>Низкие цены</h3>
              <p>Ищем лучшие предложения для вас</p>
            </div>
          </div>

          <div className="bonus-item">
            <div className="bonus-image">
              <img src={believeImage} alt="Верим в Вас" />
            </div>
            <div className="bonus-text">
              <h3>Верим в Вас</h3>
              <p>Предоставляем персональные скидки</p>
            </div>
          </div>
        </div>
      </section>

      <h2>Поиск серверов</h2>
      {/* Attach ref to ServerSelector */}
      <div ref={serverSelectorRef}>
        <ServerSelector />

      </div>

      <div className="footer">
        <a>eternity 2024</a>
        <span />
        <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/">
          {'Публичная оферта'}
        </a>
        <a className="footer-link" href="https://documents.ety.one/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/">
          {'Обработка персональных данных'}
        </a>
      
       
      
      </div>
    </div>
  );
};

export default HomePage;
