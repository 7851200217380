import React, { useState, useEffect } from "react";
import Tabs from "./Tabs";
import ApiService from "../ApiService";

const ServerDetails = ({ selectedServer }) => {
  const [activeTab, setActiveTab] = useState("overview");
  const [configData, setConfigData] = useState(null);
  const [loadingConfig, setLoadingConfig] = useState(false);


  // Helper to get status label based on server status
  const getStatusLabel = (status) => {
    switch (status) {
      case "AVAILABLE":
        return "Доступен";
      case "DELETED":
        return "Удален";
      case "WAITING_FOR_ACTIVATION":
        return "Ожидает активации";
      case "SERVICE":
        return "В обслуживании";
      default:
        return "Неизвестный статус";
    }
  };

  // Helper to get access message based on server status
  const getAccessMessage = (status) => {
    switch (status) {
      case "AVAILABLE":
        return "Вы получили одноразовый пароль на почту или в тикет. Настоятельно рекомендуем сменить его";
      case "WAITING_FOR_ACTIVATION":
        return "Сервер ожидает активации. Информация о доступе станет доступна после активации.";
      case "SERVICE":
        return "Сервер находится в обслуживании. Доступ к серверу временно ограничен.";
      case "DELETED":
        return "Сервер был удален. Доступ к нему невозможен.";
      default:
        return "Информация о доступе недоступна.";
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      if (activeTab === "info" && selectedServer?.configurationId) { // Check if selectedServer and configId are available
        setLoadingConfig(true);
        try {
          const config = await ApiService.getSpecificConfig(selectedServer.configurationId);
          setConfigData(config.data);
        } catch (error) {
          console.error("Ошибка при получении конфигурации:", error);
        } finally {
          setLoadingConfig(false);
        }
      }
    };
    fetchConfig();
  }, [activeTab, selectedServer]); // Add selectedServer as a dependency

  // Early return if selectedServer is null
  if (!selectedServer) {
    return (
      <div className="server-details">
        Выберите сервер на панели слева, чтобы увидеть детальную информацию
      </div>
    );
  }

  return (
    <div className="server-details">
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === "overview" && (
        <div className="tab-content">
          <h3>Статус</h3>
          <div className={`status-${selectedServer.status.toLowerCase()}`}>
            {getStatusLabel(selectedServer.status)}
          </div>

          <h3>Доступ</h3>
          <div className="access">
            <span className="access-ip">IP: {selectedServer.ip}</span>
            <p>{getAccessMessage(selectedServer.status)}</p>
          </div>

          <h3>Имя сервера</h3>
          <div className="server-name-display">{selectedServer.name}</div>
        </div>
      )}
      {activeTab === "info" && (
        <div className="tab-content">
          <h3>Информация о конфигурации</h3>
          {loadingConfig ? (
            <p>Загрузка конфигурации...</p>
          ) : configData ? (
            <div className="config-details">
              <p><strong>Процессор:</strong> {configData.processor}</p>
              <p><strong>Память:</strong> {configData.memory} GB ({configData.memoryType})</p>
              <p><strong>Хранилище:</strong> {configData.storage} GB ({configData.storageType})</p>
              <p><strong>Локация:</strong> {configData.location}</p>
              <p><strong>Канал:</strong> {configData.channel}</p>
              <p><strong>SSD:</strong> {configData.hasSsd ? "Да" : "Нет"}</p>
              <p><strong>HDD:</strong> {configData.hasHdd ? "Да" : "Нет"}</p>
              <p><strong>Цена:</strong> {configData.price} P</p>
              {/* You can add more fields here if needed */}
            </div>
          ) : (
            <p>Конфигурация недоступна</p>
          )}
        </div>
      )}

      {activeTab === "management" && (
        <div className="tab-content">
          <h3>Управление</h3>
          <p>Вы можете оставить заявку на переустановку, перезагрузку или удаление выделенного севрера через создание тикета.</p>
        </div>
      )}
    </div>
  );
};

export default ServerDetails;
