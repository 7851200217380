import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import ApiService from "../ApiService";
import "./Dashboard.css";
import Sidebar from "../components/DashboardSidebar";
import ServerList from "../components/ServerList";
import ServerDetails from "../components/ServerDetails";
import ConfigurationManagement from "../components/ConfigurationManagement";
import ServerManagement from "../components/ServerManagement";
import TicketList from "../components/TicketList";
import TicketInfo from "../components/TicketInfo";
import TicketManagement from "../components/TicketManagement";
import Invoices from "../components/Invoices";

const Dashboard = () => {
    const [servers, setServers] = useState([]); // State to store servers list
    const [selectedServer, setSelectedServer] = useState(null);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [activeTab, setActiveTab] = useState("servers");
    const [username, setUsername] = useState('');
    const [role, setRole] = useState("USER");
    const [currentPage, setCurrentPage] = useState(0); // For pagination
    const [client, setClient] = useState(null);

    const location = useLocation();
    const depositParam = new URLSearchParams(location.search).get("deposit");

    const isTokenExpired = (token) => {

        const decodedToken = jwtDecode(token);
        return decodedToken.exp * 1000 < Date.now();
    };


    const fetchClient = async () => {
        try {
            const response = await ApiService.getClient();
            setClient(response.data);
        } catch (error) {
            console.error("Error fetching client:", error);
        }
    };
    useEffect(() => {
        fetchClient();
    }, []);

    const checkAndRefreshToken = async () => {
        const token = localStorage.getItem("token");

        if (token) {
            const decodedToken = jwtDecode(token);
            setUsername(decodedToken.sub);
            setRole(decodedToken.role);

            if (isTokenExpired(token)) {
                const refreshToken = localStorage.getItem("refreshToken");
                try {
                    const data = await ApiService.refresh(refreshToken);
                    localStorage.setItem("token", data.data.accessToken);
                    localStorage.setItem("refreshToken", data.data.refreshToken);
                } catch (error) {
                    console.error("Failed to refresh token:", error);
                }
            }
        }
    };

    const fetchServers = async (page) => {
        try {
            const response = await ApiService.getMyServers(page);
            setServers(response.data);
        } catch (error) {
            console.error("Failed to fetch servers:", error);
        }
    };

    useEffect(() => {
        checkAndRefreshToken();
        fetchServers(currentPage); // Fetch servers when component mounts or page changes
    }, [currentPage]);

    const handleNextPage = () => {
        if (currentPage < 15) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <div className="dashboard-container">
            <Sidebar
                role={role}
                username={username}
                client={client}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                initialDepositAmount={depositParam}
            />
               {activeTab === "bills" && (
                <Invoices />
            )}
            {activeTab === "servers" && (
                <div className="debil-con">
                    <ServerList
                        servers={servers}
                        client={client}
                        selectedServer={selectedServer}
                        setSelectedServer={setSelectedServer}
                    />
                    <ServerDetails selectedServer={selectedServer} />


                </div>
            )}
            {activeTab === "tickets" && (
                <div className="debil-con">
                    <TicketList

                        client={client}
                        selectedTicket={selectedTicket}
                        setSelectedTicket={setSelectedTicket}
                    />
                    <TicketInfo selectedTicket={selectedTicket} />


                </div>
            )}
            
            {activeTab === "configurations" && role === "ADMIN" && (
                <ConfigurationManagement />
            )}
            {activeTab === "admservers" && role === "ADMIN" && (
                <ServerManagement />
            )}

            {activeTab === "admtickets" && role === "ADMIN" && (
                <TicketManagement />
            )}

        </div>
    );
};

export default Dashboard;
