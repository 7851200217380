import React from "react";


const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="tabs">
      <div
        className={`tab ${activeTab === "overview" ? "active" : ""}`}
        onClick={() => setActiveTab("overview")}
      >
        Обзор
      </div>
      <div
        className={`tab ${activeTab === "info" ? "active" : ""}`}
        onClick={() => setActiveTab("info")}
      >
        Информация
      </div>
      <div
        className={`tab ${activeTab === "management" ? "active" : ""}`}
        onClick={() => setActiveTab("management")}
      >
        Управление
      </div>
    </div>
  );
};

export default Tabs;
