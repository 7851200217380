import React from "react";
import DOMPurify from "dompurify";
import Tabs from "./Tabs";
import ApiService from "../ApiService";
import "./TicketInfo.css";

// Updated utility function to convert URLs in the text to clickable links
const convertLinks = (text) => {
    if (!text) return "";

    // Improved regular expression to match URLs, including domains without a path
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Replace URLs with anchor tags
    return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
};

const TicketInfo = ({ selectedTicket }) => {
    // Format the ticketCreatedDate to display as "22 сентября 2024"
    const formatDate = (dateInMillis) => {
        const date = new Date(dateInMillis);
        return new Intl.DateTimeFormat("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric"
        }).format(date);
    };

    if (!selectedTicket) {
        return (
            <div className="server-details">
                Выберите тикет на панели слева, чтобы увидеть детальную информацию
            </div>
        );
    }

    // Convert and sanitize ticket and answer text
    const sanitizedTicketContent = DOMPurify.sanitize(convertLinks(selectedTicket.ticket));
    const sanitizedAnswerContent = DOMPurify.sanitize(convertLinks(selectedTicket.answer));

    return (
        <div className="server-details">
            <div className="tab-content">
                <div className={`status-${selectedTicket.ticketStatus.toLowerCase()}`}>
                    Создан {formatDate(selectedTicket.ticketCreatedDate)}
                </div>
                
                <h2 className="ticket-title">Тема: {selectedTicket.ticketTitle}</h2>
                
                <div className="server-name-display">{selectedTicket.name}</div>

                {/* Render the sanitized ticket content with clickable links */}
                <p
                    className="ticket-content"
                    dangerouslySetInnerHTML={{ __html: sanitizedTicketContent }}
                />

                {selectedTicket.ticketStatus === "OPEN" ? (
                    <div>
                        <h3>Тикет ожидает ответа</h3>
                    </div>
                ) : (
                    <div>
                        <h3>Ответ оператора etyCloud</h3>
                        {/* Render the sanitized answer content with clickable links */}
                        <p
                            className="ticket-content"
                            dangerouslySetInnerHTML={{ __html: sanitizedAnswerContent }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TicketInfo;
