import React, { useState, useEffect } from "react";
import ApiService from "../ApiService";
import "./ConfigurationManagement.css";

const ConfigurationManagement = () => {
    const [config, setConfig] = useState({
        date: Date.now(),
        processor: "",
        memory: 0,
        memoryType: "",
        storage: 0,
        storageType: "",
        location: "",
        channel: "",
        hasSsd: false,
        hasHdd: false,
        available: false,
        price: 0.0,
    });
    const [configs, setConfigs] = useState([]);
    const [page, setPage] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedConfigId, setSelectedConfigId] = useState(null);

    useEffect(() => {
        fetchConfigs(page);
    }, [page]);

    const fetchConfigs = async (page) => {
        try {
            const response = await ApiService.getAdminAllConfigs(page);
            setConfigs(response.data.content); // Assuming the response has a `data` field
        } catch (error) {
            console.error("Error fetching configs:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig({
            ...config,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                await ApiService.updateConfig(config);
                console.log("Configuration updated successfully");
            } else {
                await ApiService.updateConfig(config); // Should be addConfig for adding a new config
                console.log("Configuration added successfully");
            }
            fetchConfigs(page); // Refresh the list after adding/editing
            resetForm();
        } catch (error) {
            console.error("Error submitting configuration:", error);
        }
    };

    const handleEdit = (config) => {
        setConfig(config);
        setIsEditMode(true);
        setSelectedConfigId(config.id); // Assuming each config has an `id`
    };

    const resetForm = () => {
        setConfig({
            date: Date.now(),
            processor: "",
            memory: 0,
            memoryType: "",
            storage: 0,
            storageType: "",
            location: "",
            channel: "",
            hasSsd: false,
            hasHdd: false,
            available: false,
            price: 0.0,
        });
        setIsEditMode(false);
        setSelectedConfigId(null);
    };

    const handlePageChange = (direction) => {
        if (direction === "next") {
            setPage((prev) => prev + 1);
        } else if (direction === "prev" && page > 0) {
            setPage((prev) => prev - 1);
        }
    };

    const handleCreateNewConfig = () => {
        resetForm(); // Reset the form fields for new configuration
    };

    return (
        <div className="config-management-container">
            <div className="server-list">
                <h3>Server List</h3>
                {configs.map((config) => (
                    <div key={config.id} className="config-item">
                        <p>{config.processor} - {config.memory} GB</p>
                        <p>{config.price} P, id {config.id}</p>
                        <button onClick={() => handleEdit(config)}>Edit</button>
                    </div>
                ))}
                <div className="pagination">
                    <button onClick={() => handlePageChange("prev")} disabled={page === 0}>Previous</button>
                    <button onClick={() => handlePageChange("next")}>Next</button>
                </div>
            </div>
            
            <div className="config-form-container">
                <button onClick={handleCreateNewConfig} className="create-config-btn">
                    Create New Configuration
                </button>

                <form onSubmit={handleSubmit} className="config-form">
                    <label>{config.id}</label>
                    <label>
                        Processor:
                        <input type="text" name="processor" value={config.processor} onChange={handleChange} />
                    </label>
                    <label>
                        Memory (GB):
                        <input type="number" name="memory" value={config.memory} onChange={handleChange} />
                    </label>
                    <label>
                        Memory Type:
                        <input type="text" name="memoryType" value={config.memoryType} onChange={handleChange} />
                    </label>
                    <label>
                        Storage (GB):
                        <input type="number" name="storage" value={config.storage} onChange={handleChange} />
                    </label>
                    <label>
                        Storage Type:
                        <input type="text" name="storageType" value={config.storageType} onChange={handleChange} />
                    </label>
                    <label>
                        Location:
                        <input type="text" name="location" value={config.location} onChange={handleChange} />
                    </label>
                    <label>
                        Channel:
                        <input type="text" name="channel" value={config.channel} onChange={handleChange} />
                    </label>
                    <label>
                        Has SSD:
                        <input type="checkbox" name="hasSsd" checked={config.hasSsd} onChange={handleChange} />
                    </label>
                    <label>
                        Has HDD:
                        <input type="checkbox" name="hasHdd" checked={config.hasHdd} onChange={handleChange} />
                    </label>
                    <label>
                        Available:
                        <input type="checkbox" name="available" checked={config.available} onChange={handleChange} />
                    </label>
                    <label>
                        Price:
                        <input type="number" step="0.01" name="price" value={config.price} onChange={handleChange} />
                    </label>
                    <button type="submit">{isEditMode ? "Update Configuration" : "Add Configuration"}</button>
                </form>
            </div>
        </div>
    );
};

export default ConfigurationManagement;
